/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Material */
@import "theme/material";

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {
  font-family: "Ubuntu Regular";
  font-weight: normal;
  font-style: normal;
  src: local(""),
  url("assets/fonts/ubuntu-regular.woff2") format('woff2'),
  url("assets/fonts/ubuntu-regular.woff") format('woff'),
  url("assets/fonts/ubuntu-regular.ttf") format('truetype'),
  url("assets/fonts/ubuntu-regular.svg#Ubuntu Regular") format('svg');
}

html {
  --ion-font-family: "Ubuntu Regular", system-ui, -apple-system, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

// Clickable
.clickable {
  cursor: pointer;
  text-decoration: underline;
}

// Link-Icon zu externen Seiten
.link-icon {
  margin-left: 0.1em;
  height: 0.7em;
}

// Schriftgröße des Card-Contents
.card-content-md {
  font-size: 16px;
}

// Material-Design Formulare Globales Aussehen
mat-form-field.mat-form-field {
  display: block;
}

mat-form-field.mat-form-field {

  ion-icon[matPrefix] {
    margin-right: 0.6rem;
    transform: translateY(2px);
  }

  ion-button[matSuffix]:not(.submit-button) {
    --padding-top: 0;
    --padding-end: 0;
    --padding-bottom: 0;
    --padding-start: 0.4rem;
    margin-top: -2rem;
    margin-right: 0;
    margin-bottom: -1.2rem;
    margin-left: 0;
  }

}

// Ungewünschte Tool-Tipps von den Icons entfernen
ion-icon {
  pointer-events: none;
}

// Style für das Project-Select-Popover
.project-select-popover {

  //--offset-y: 1rem;

  --box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);

  border: thin solid var(--ion-color-light-shade);

}


/*
Animation - Pulsieren

Beim Zuweisen der Klasse "pulsating" wird das Element dreimal aus- und eingeblendet.
Man muss die die pulsating-Klasse nach 800 ms manuell wieder entfernen.
*/
@keyframes pulsating {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}

.pulsating {
  animation: pulsating 120ms alternate;
  animation-iteration-count: 6;
}

// Bottom-Spacer
.bottom-spacer {
  margin-top: 6rem;
}


// Export-Seite: Exportformat Auswahl
ion-alert.pages-export-format {
  --width: 400px;
  --max-width: 94%;
}

// für NotificationService.showErrorMessage()
.error-message-alert {
  --background: Snow;
  --min-width: 50%;
}
